import * as React from "react"
import Modal from "../components/modal"
import NewsletterForm from "../components/newsletter-form"

const NewsletterSection = () => (
  <section className="bg-green-400 green-blob">
    <div className="etr-container">
      <div className="grid md:grid-cols-2 md:gap-6  py-10 sm:py-20">
        <div className="flex flex-col justify-center h-full">
          <div className="max-w-content-lg">
            <h2 className="font-tiempos text-4xl leading-tight sm:text-6xl sm:leading-tight mb-6">
              Bądźmy w stałym kontakcie mailowym!
            </h2>
            <p className="font-tiempos leading-tight text-3xl sm:text-4xl sm:leading-tight mb-9 text-black">
              Będziemy Ci przysyłać:
            </p>

            <ul className="etr-list">
              <li>
                Zweryfikowane miejsca, do których możesz się udać po pomoc.
              </li>
              <li>Artykuły przyjazne Twojej głowie.</li>
              <li>Materiały edukacyjne naszej produkcji.</li>
              <li>Nowe projekty Fundacji, które ...</li>
            </ul>
          </div>
        </div>
        <div className="mt-10 sm:mt-0 px-6 flex items-center justify-center">
          <Modal
            buttonClass="button w-full max-w-md mx-auto inline-block"
            buttonText="Dołączam już dziś!"
          >
            <NewsletterForm />
          </Modal>
        </div>
      </div>
    </div>
  </section>
)

export default NewsletterSection
