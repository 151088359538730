import * as React from "react"
import Modal from "../components/modal"
import NewsletterForm from "../components/newsletter-form"
import HeroImage from "../images/content/hero-image.svg"

const Hero = () => (
  <section className="etr-container mb-16 sm:mb-52">
    <div className="grid md:grid-cols-2 md:gap-6  pt-10 sm:pt-20">
      <div className="flex flex-col justify-center h-full">
        <div className="max-w-content-lg w-full">
          <h1 className="font-tiempos text-4xl leading-tight sm:text-6xl sm:leading-tight mb-6">
            Wszyscy mówią,
            <br /> że w nastolatku szaleje{" "}
            <span className="decoration-paint">burza hormonów</span>
          </h1>
          <p className="font-tiempos leading-tight text-3xl sm:text-4xl sm:leading-tight mb-9 text-black">
            My chcemy pokazać Ci,{" "}
            <span className="decoration-line">jak ją ogarnąć.</span>
          </p>
          <p className="text-gray-700  text-xl sm:text-2xl mb-10 tracking-paragraph">
            W internecie coraz więcej mówi się o dbaniu o{" "}
            <b>zdrowie psychiczne</b>. Co jakiś czas zweryfikujemy i prześlemy
            Ci linki do miejsc i artykułów na ten temat.
          </p>
          <div>
            <Modal buttonClass="button-tiger button-arrow" buttonText="Zapisz się na listę">
              <NewsletterForm/>
            </Modal>
          </div>
        </div>
      </div>
      <div className="mt-10 sm:mt-0 px-6">
        <img className="w-full" src={HeroImage} alt="" />
      </div>
    </div>
  </section>
)

export default Hero
