import React, { useState } from "react"

const Modal = ({ buttonClass, buttonText, children }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <button className={buttonClass} onClick={() => setVisible(true)}>
        {buttonText}
      </button>
      {visible ? (
        <div className="fixed w-full  h-full top-0 left-0 z-50 px-8 py-8 flex items-center justify-center bg-opacity-90 backdrop-filter backdrop-blur-md bg-green-400">
          <div className="bg-white overflow-hidden relative rounded-2xl max-w-5xl w-full max-h-full overflow-y-auto">
            <button
            className="absolute top-6 right-6 cursor-pointer hover:opacity-80 transition duration-150"
              aria-label="Zamknij okienko."
              onClick={() => setVisible(false)}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.98937 0.868051C2.40358 0.282264 1.45384 0.282264 0.868051 0.868051C0.282264 1.45384 0.282264 2.40358 0.868051 2.98937L6.87843 8.99975L0.86805 15.0101C0.282264 15.5959 0.282265 16.5457 0.868052 17.1315C1.45384 17.7172 2.40359 17.7172 2.98937 17.1314L8.99975 11.1211L15.0101 17.1314C15.5959 17.7172 16.5456 17.7172 17.1314 17.1314C17.7172 16.5456 17.7172 15.5959 17.1314 15.0101L11.1211 8.99975L17.1314 2.98937C17.7172 2.40358 17.7172 1.45384 17.1314 0.86805C16.5456 0.282264 15.5959 0.282265 15.0101 0.868052L8.99974 6.87842L2.98937 0.868051Z"
                  fill="#475059"
                />
              </svg>
            </button>
            <div className="sm:grid grid-cols-2">
                <div className="bg-green-100 p-10 flex items-center justify-center">
                    <img src="/illustrations/modal.svg" alt="" className="max-w-full  mx-auto"/>
                </div>
                <div className="p-10"> 
                       {children}
                </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Modal
