import * as React from "react"

const TextImage = ({ children, image, alternative, css }) => {
  return (
    <section className={css}>
      <div className="etr-container mb-20 sm:mb-40">
        <div className={`grid md:grid-cols-2 gap-x-6`}>
          <div
            className={` mx-auto max-w-content w-full flex flex-col justify-center order-3 ${
              alternative ? "md:order-2" : "md:order-1 md:ml-auto"
            }`}
          >
            {children}
          </div>
          <div
            className={`order-2 md:order-1 mb-10 md:mb-0 ${
              alternative ? "ml-auto" : null
            } md:max-w-content w-full`}
          >
            {image ? (
              <img className="max-w-full  mx-auto" src={image} alt="" />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextImage
