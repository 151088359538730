import './src/styles/global.css';
import keyboardOnlyOutlines from '@moxy/keyboard-only-outlines'

keyboardOnlyOutlines()

console.log(`%c                                    
  With the moonlight to guide you,  
  feel the joy of being alive       
  The day that you stop running     
  Is the day that you arrive        
  (...)                             
  Stop chasing shadows              
  just enjoy the ride               
                                    `, 'font-size: 20px; background-color: #00B140; color: #fff;')