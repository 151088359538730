import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import {Link}  from "gatsby"

const Newsletter = () => {
  const formElem = useRef()
  const action = "https://enjoytheride.pl/.netlify/functions/subscribe"
  const [status, setStatus] = useState("initial")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {
    setStatus("loading")
    axios
      .post(action, data)
      .then(res => {
        if (res.data.id) {
          setStatus("success")
        }
      })
      .catch(() => {
        setStatus("api-error")
      })
  }

  const showForm = (
    <section>
      <h2 className="text-4xl mb-6">Zapisz się na newsletter</h2>
      <p className="mb-8">
        Praesent porttitor, nulla vitae posuere iaculis, arcu nisl dignissim dolor, a pretium mi sem ut ipsum. Pellentesque posuere. Fusce neque.
      </p>
      <form method="post" onSubmit={handleSubmit(onSubmit)} ref={formElem}>
        <p>
          <input
            className=" focus:border-gray-500 w-full block border-gray-200 border-2 rounded-lg p-4"
            aria-label="Imię"
            placeholder="Imię"
            name="name"
            {...register("name", { required: true })}
          />
          {errors.name && <span className="text-tiger-700">To pole jest wymagane</span>}
        </p>
        <p>
          <input
            className=" focus:border-gray-500 w-full  block border-gray-200 border-2 rounded-lg   p-4"
            aria-label="Twój adres e-mail"
            placeholder="Twój adres e-mail"
            name="email"
            {...register("email", { required: true })}
          />

          {errors.email && <span className="text-tiger-700">To pole jest wymagane</span>}
        </p>
        <button className="button-tiger w-full" type="submit">
          Zapisz mnie
        </button>
      </form>

      <p className="text-sm mt-8">
      Zapisując się na newsletter zgadzasz się na przetwarzanie Twoich danych osobowych. Więcej informacji <Link className="underline underline-gray-700" to="/polityka-prywatnosci-newsletter" title="Dodatkowe informacje o przetwarzaniu danych osobowych">tutaj</Link>
      </p>
    </section>
  )

  const success = (
    <section>
      <h2 className="text-4xl mb-6">Dzięki, to prawie wszystko!</h2>
      <p>Za chwilę otrzymasz email z prośbą o potwierdzenie podanego adresu.</p>
      <p>
        Jeśli wiadomość nie przyszła to sprawdź folder Spam i Oferty.
      </p>
      <p>
        Adres: kontakt@enjoytheride.pl <br />
        Jeśli tego nie zrobisz nie będziemy mogli wysyłać Ci żadnych wiadomości.
       </p>
       <p>
        Do przeczytania wkrótce.
        <br /> <br />
        Team Enjoy the Ride
      </p>
    </section>
  )

  return <div>{status === "success" ? success : showForm}</div>
}

export default Newsletter
