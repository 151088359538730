import Hero from "../../../../src/components/hero";
import Seo from "../../../../src/components/seo";
import Why from "../../../../src/components/why";
import TextImage from "../../../../src/components/text-image";
import NewsletterSection from "../../../../src/components/newsletter-section";
import * as React from 'react';
export default {
  Hero,
  Seo,
  Why,
  TextImage,
  NewsletterSection,
  React
};