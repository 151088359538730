import * as React from "react"

const SubpageHero = ({ children, image }) => {
  return (
    <section className="bg-green-100 sm:min-h-xl py-8 sm:py-24 mb-24 sm:mb-40 subpage-hero">
      <div className="etr-container">
        <div className={image ? `grid md:grid-cols-2 gap-10` : null}>
          <div
            className={`flex flex-col justify-center w-full leading-8 ${image ? 'md:ml-auto mr-0 max-w-content-plus ' : 'max-w-content-lg mx-auto'}`}
          >
            {children}
          </div>
          <div
            className="order-2 md:order-1 max-w-content w-full"
          >
            {image ? (
              <img className="max-w-full mt-6 md:mt-0  mx-auto" src={image} alt="" />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubpageHero
