// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kontakt-mdx": () => import("./../../../src/pages/kontakt.mdx" /* webpackChunkName: "component---src-pages-kontakt-mdx" */),
  "component---src-pages-o-fundacji-mdx": () => import("./../../../src/pages/o-fundacji.mdx" /* webpackChunkName: "component---src-pages-o-fundacji-mdx" */),
  "component---src-pages-polityka-prywatnosci-mdx": () => import("./../../../src/pages/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-mdx" */),
  "component---src-pages-polityka-prywatnosci-newsletter-mdx": () => import("./../../../src/pages/polityka-prywatnosci-newsletter.mdx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-newsletter-mdx" */),
  "component---src-pages-statut-mdx": () => import("./../../../src/pages/statut.mdx" /* webpackChunkName: "component---src-pages-statut-mdx" */)
}

