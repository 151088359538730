import * as React from "react"
import { Link } from "gatsby"
import Quote from "./quote"
import Logo from "../images/enjoy-the-ride.svg"

const Why = () => (
  <section className="blue-blob">
    <div className="etr-container grid md:grid-cols-5-7 gap-4 mb-15 sm:mb-52">
      <div className="md:flex md:justify-center mb-12 md:mb-0">
        <h2 className="font-tiempos text-4xl leading-tight sm:text-6xl sm:leading-tight md:max-w-content-xs w-full sticky top-28">
          Dlaczego powstała Fundacja
          <img src={Logo} alt="Enjoy the ride" className="block w-56 md:w-full mt-6 md:mt-10" />
        </h2>
      </div>
      <div>
        <div className="max-w-content-lg">
          <p className="text-xl leading-6 sm:text-2xl sm:leading-8  mb-8 tracking-paragraph">
            Enjoy The Ride to Twoja <b>bezpieczna przestrzeń</b>. Chcemy pokazać Ci, jak radzić sobie z emocjami.
          </p>
          <p className="text-xl leading-6 sm:text-2xl sm:leading-8  tracking-paragraph mb-15">
            Zapewne nie wiemy dokładnie, co czujesz.
            Jednak założymy się, że znasz takie lub podobne teksty:
          </p>
          <ul className="grid justify-center sm:grid-cols-2 gap-y-14 gap-x-4 mb-20">
            <li className="list-none ml-0">
              <Quote className="quote-green"><p className="h500">Inni mają<br/> gorzej!</p></Quote>
            </li>
            <li className="list-none ml-0">
              <Quote className="quote-yellow"><p className="h500">Przesadzasz,<br/> po co ryczysz?!</p></Quote>
            </li>
            <li className="list-none ml-0">
              <Quote className="quote-azure"><p className="h500">Czemu czwórka,<br/> a nie piątka?</p></Quote>
            </li>
            <li className="list-none ml-0">
              <Quote className="quote-tiger h-full"><p className="h500">A ja w Twoim wieku...!</p></Quote>
            </li>
          </ul>
          <p className="text-xl leading-6 sm:text-2xl sm:leading-8 tracking-paragraph mb-15">
            Prawdopodobnie nie raz czujesz, że nikt Cię nie rozumie... prawda?
          </p>
          <Link className="button-border button-arrow" to="/o-fundacji">Dowiedz się więcej o Fundacji</Link>
        </div>
      </div>
    </div>
  </section>
)

export default Why
